import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
import Kontakty from 'components/kontakty';
import FullWidth from 'components/fullwidth';
import obr from '../images/insolvence-01.jpg';

const Sluzba = ({ data }) => (
  <Layout>
    <FullWidth>
      <Head pageTitle={data.insolvenceJson.title} />
      <div className="c1c1">
        <div className="column">
          <Title as="h2" size="large">
            {data.insolvenceJson.title}
          </Title>

          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.insolvenceJson.content.childMarkdownRemark.html,
            }}
          />
        </div>
        <div className="column lp2">
          <img src={obr} alt="Insolvence ilustrace" />
        </div>
      </div>
      <div className="cenik">
        <h3 className="kontaktni-osoby">Ceník</h3>
        <ul>
          <li>
            <strong>Zpracování návrhu na oddlužení (jednotlivec)</strong>
            <em>4&#8239;840,–&#8239;Kč</em>
          </li>
          <li>
            <strong>
              Zpracování návrhu na oddlužení (společné oddlužení manželů)
            </strong>
            <em>7&#8239;260,–&#8239;Kč</em>
          </li>
          <li>
            <strong>Zpracování návrhu na konkurs</strong>
            <em>
              5&#8239;000,– až 10&#8239;000,–&#8239;Kč dle náročnosti návrhu
            </em>
          </li>
          <li>
            <strong>Insolvenční poradenství</strong>
            <em>600,–&#8239;Kč</em>
          </li>
          <li>
            <strong>Prodej majetku</strong>
            <em>Bezplatná služba</em>
          </li>
        </ul>
      </div>
      <Box>
        <h3 className="kontaktni-osoby">Kontaktní osoby</h3>
      </Box>
      <Kontakty items={data.insolvenceJson.kontakty} />
    </FullWidth>
  </Layout>
);

Sluzba.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sluzba;

export const query = graphql`
  query InsolvenceQuery {
    insolvenceJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      kontakty {
        name
        occupation
        phone1
        phone2
        email
      }
    }
  }
`;
